import { fireShopByGAEvent } from '@modules/ga/events/custom/shop-by/shopByCreator';

export const trackShopByExpress = (enabled: boolean) => {
  if (enabled) {
    fireShopByGAEvent({ label: 'EXPRESS' });
  }
};

export const trackShopBy = (itemName?: string | null) => {
  fireShopByGAEvent({ label: itemName ?? '' });
};
