import { NoResultsWithReload } from '@components/NoResultsWithReload/NoResultsWithReload';
import { ModuleListCreator } from '@components/Modules/ModuleListCreator/ModuleListCreator';
import { ProductTileSkeleton } from '@components/Skeletons/ProductTileSkeleton/ProductTileSkeleton';
import { SkeletonWrapper } from '@components/Skeletons/SkeletonWrapper/SkeletonWrapper';
import { useReorderModule } from './useReorderModule';
import { useHomeFeed } from './useHomeFeed';

export const HomePageFeed = () => {
  const { data: homeFeed, loading: isHomeFeedLoading } = useHomeFeed();
  const { data: reorderModule, isReorderModuleLoading } = useReorderModule();

  if (isHomeFeedLoading) {
    return <SkeletonWrapper count={12} skeleton={<ProductTileSkeleton />} />;
  }

  const isEmptyPage = !(homeFeed.length > 0 || !!reorderModule);
  if (!isHomeFeedLoading && isEmptyPage) {
    return <NoResultsWithReload />;
  }

  return (
    <ModuleListCreator
      data={homeFeed}
      reorderModule={reorderModule}
      reorderLoading={isReorderModuleLoading}
    />
  );
};
