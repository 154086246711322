import Head from 'next/head';
import { useRouter } from 'next/router';
import { routing } from '@constants/routing';
import { CANONICAL_LINK_ORIGIN } from '@constants/canonicalLinkOrigin';

interface CanonicalLinkProps {
  customPath?: string;
}

export const CanonicalLink = ({ customPath }: CanonicalLinkProps) => {
  const router = useRouter();
  const path = customPath || router.asPath;
  const url = CANONICAL_LINK_ORIGIN + path;
  let canonicalUrl: URL;

  try {
    canonicalUrl = new URL(url);
  } catch {
    return null;
  }

  let idSearchParam: null | string = null;
  const isBrowsePage = canonicalUrl.pathname.includes(routing.browse);

  if (isBrowsePage) {
    idSearchParam = canonicalUrl.searchParams.get('id');
  }

  canonicalUrl.hash = '';
  canonicalUrl.search = '';

  if (isBrowsePage && idSearchParam) {
    canonicalUrl.searchParams.set('id', idSearchParam);
  }

  return (
    <Head>
      <link rel="canonical" key="canonical" href={canonicalUrl.toString()} />
    </Head>
  );
};
