import cx from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { Typography } from '@mui/material';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { useLocalization } from '@hooks/useLocalization';
import { ExpressContext } from '@context/ExpressContext/ExpressContext';
import { useModifyOrderContext } from '@context/ModifyOrderContext/ModifyOrderContext';
import { ExpressIcon } from '@components/UI/ExpressIcon/ExpressIcon';
import { ExpressOverlay } from '@components/ExpressOverlay/ExpressOverlay';
import { isFirstExpressOverlayVar } from '@graphql/variables/isFirstExpressOverlayVar';
import { useAuthContext } from '@modules/auth/context';
import styles from './ExpressToggle.module.scss';

interface ExpressToggleProps {
  onePillMode?: boolean;
  className?: string;
  onToggle?: (enabled: boolean) => void;
  shopBy?: boolean;
}
type ToggleType = (value: boolean, shouldCleanCache?: boolean) => void;

export const ExpressToggle = ({ className, onePillMode, onToggle, shopBy }: ExpressToggleProps) => {
  const { t } = useLocalization();
  const { isModifyMode } = useModifyOrderContext();
  const { userStatus } = useAuthContext();
  const isFirstExpressOverlay = useReactiveVar(isFirstExpressOverlayVar);
  const isExpressToggleSwitch: boolean = isFeatureEnabled(DYNAMIC_FEATURES.EXPRESS_TOGGLE);

  const handleExpressToggle = (toggle: ToggleType, enabled: boolean) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toggle(!enabled);
    onToggle?.(!enabled);
  };

  const toggleRelatedContainerStyle = isExpressToggleSwitch
    ? styles.container_toggle
    : styles.container;
  const toggleRelatedButtonStyle = isExpressToggleSwitch
    ? styles.check_background_toggle
    : styles.check_background;

  return (
    <ExpressContext.Consumer>
      {({ enabled, toggle }) => {
        return (
          !!userStatus && (
            <ExpressOverlay isFirstLoad={isFirstExpressOverlay}>
              <div
                className={cx([
                  toggleRelatedContainerStyle,
                  className,
                  { [styles.one_pill]: onePillMode },
                ])}
                data-testid="express-toggle"
              >
                {onePillMode && (
                  <Typography variant="body" className={cx([styles.label_prefix])}>
                    {t('express.shopBy')}
                  </Typography>
                )}
                {isExpressToggleSwitch && <ExpressIcon className={styles.toggle_icon} />}

                <label className={styles.check}>
                  <input
                    role="switch"
                    className={cx([styles.check_input, 'sr-only'])}
                    type="checkbox"
                    onChange={() => handleExpressToggle(toggle, enabled)}
                    checked={enabled}
                    disabled={isModifyMode}
                  />

                  <span
                    className={cx(toggleRelatedButtonStyle, {
                      [styles.disabled]: isModifyMode,
                    })}
                  >
                    {!isExpressToggleSwitch && <ExpressIcon />}
                  </span>

                  <span className="sr-only">
                    {shopBy && t('express.shopBy')}
                    {t('express.infoTipTitle')}
                  </span>
                </label>
              </div>
            </ExpressOverlay>
          )
        );
      }}
    </ExpressContext.Consumer>
  );
};
