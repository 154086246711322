import { useRouter } from 'next/router';
import { useLocalization } from '@hooks/useLocalization';
import { NoResultsTemplate } from '@components/NoResultsTemplate/NoResultsTemplate';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';

export const NoResultsWithReload = () => {
  const router = useRouter();
  const { toggle: toggleExpress } = useExpressContext();
  const { t } = useLocalization();

  const handleClearFilters = () => {
    const path = router.asPath.replace(/\?.*/, '');

    router.push({
      pathname: path,
    });
    toggleExpress(false);
  };

  return (
    <NoResultsTemplate
      title={t('noResult.title')}
      subtitle={t('noResult.subtitle')}
      buttonText={t('noResult.reload')}
      onClick={handleClearFilters}
    />
  );
};
