import { gql } from '@apollo/client';
import { FEED_MODULE_FRAGMENT } from '@graphql/fragments/home/feedModuleFragment';

export const GET_REORDER_MODULE = gql`
  query ReorderFeed {
    reorderFeed {
      ...feedModuleFragment
    }
  }
  ${FEED_MODULE_FRAGMENT}
`;
