import { useEffect, useRef } from 'react';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { StickyContainer } from '@components/StickyContainer/StickyContainer';

interface StickyToHeaderContainerProps {
  children: React.ReactNode;
  withBackground?: boolean;
  withFullWidthBottomShadow?: boolean;
  className?: string;
  hasLeftNav?: boolean;
}

export const StickyToHeaderContainer = ({
  children,
  withBackground = false,
  withFullWidthBottomShadow = false,
  className,
  hasLeftNav,
}: StickyToHeaderContainerProps) => {
  const { headerContentRef } = useHeaderContext();
  const stickyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const [headerContent] = entries;

      if (stickyRef.current) {
        const topHeight = withFullWidthBottomShadow ? 0 : 40;
        stickyRef.current.style.top = headerContent.contentRect.height + topHeight + 'px';
      }
    });

    if (headerContentRef.current) {
      resizeObserver.observe(headerContentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [headerContentRef, withFullWidthBottomShadow, stickyRef.current]);

  return (
    <StickyContainer
      withBackground={withBackground}
      withFullWidthBottomShadow={withFullWidthBottomShadow}
      ref={stickyRef}
      className={className}
      hasLeftNav={hasLeftNav}
    >
      {children}
    </StickyContainer>
  );
};
