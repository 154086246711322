import { NetworkStatus, useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { getFeedModule } from '@adapters/getFeedModule';
import { HomeFeedQuery, HomeFeedQueryVariables } from '@api';
import { GET_HOMEPAGE_FEED } from '@graphql/home/queries/getHomePageFeed';
import { DEFAULT_SECTIONS_TO_LOAD } from '@constants/sectionsToLoad';

const HOME_FEED_SECTIONS_INITIAL_START_INDEX = 0;
const HOME_FEED_SECTIONS_ALL = -1;

export const useHomeFeed = () => {
  const { data, fetchMore, networkStatus } = useQuery<HomeFeedQuery, HomeFeedQueryVariables>(
    GET_HOMEPAGE_FEED,
    {
      variables: {
        preview: false,
        sectionsStartingFrom: HOME_FEED_SECTIONS_INITIAL_START_INDEX,
        sectionsToLoad: DEFAULT_SECTIONS_TO_LOAD,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const memoizedData = useMemo(() => data?.homepageFeed?.map(getFeedModule) ?? [], [data]);

  useEffect(() => {
    /*
    During soft navigation. "Fetch more" postpones writing of the data to Apollo storage until all data(1st part and 2nd part) of the feed is fetched.
    On UI it leads to displaying of the empty screen.
    To avoid such behaviour there is a condition that executes "fetch more" only after first part of the data is written to the Apollo storage
    Also it guaranties that "fetch more" request won't be executed first
     */
    if (memoizedData.length === 0) return;
    if (memoizedData.length > DEFAULT_SECTIONS_TO_LOAD) return;

    fetchMore({
      variables: {
        preview: false,
        sectionsStartingFrom: DEFAULT_SECTIONS_TO_LOAD,
        sectionsToLoad: HOME_FEED_SECTIONS_ALL,
      },
    });
  }, [memoizedData, fetchMore]);

  return {
    data: memoizedData,
    loading: networkStatus === NetworkStatus.loading,
    loadingMore:
      networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.fetchMore,
  };
};
