import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { ReorderFeedQuery, ReorderFeedQueryVariables } from '@api';
import { getFeedModule } from '@adapters/getFeedModule';
import { GET_REORDER_MODULE } from '@graphql/home/queries/getReorderFeed';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const useReorderModule = () => {
  const { userInfo, isUserInfoLoading } = useAuthContext();

  const { data, loading } = useQuery<ReorderFeedQuery, ReorderFeedQueryVariables>(
    GET_REORDER_MODULE,
    {
      skip: !userInfo?.memberSinceYear,
      ssr: false,
      notifyOnNetworkStatusChange: true,
    },
  );

  const memoizedData = useMemo(
    () => (data?.reorderFeed ? getFeedModule(data?.reorderFeed) : undefined),
    [data],
  );

  return {
    data: memoizedData,
    isReorderModuleLoading: loading || isUserInfoLoading,
  };
};
