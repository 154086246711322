import { fireEvent } from '@modules/ga/emitter';
import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';
import { ShopByOptions } from '@modules/ga/events/custom/shop-by/shopByHandler';

export const fireShopByGAEvent = (data: ShopByOptions) =>
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.SHOP_BY,
    data,
  });
