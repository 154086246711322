import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './ContentContainer.module.scss';
interface ContentContainerProps {
  children: ReactNode;
  className?: string;
  position?: number;
}

export const ContentContainer = ({ children, className, position }: ContentContainerProps) => {
  return (
    <div className={cx(className, styles.container)} data-position={position}>
      {children}
    </div>
  );
};
