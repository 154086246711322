import { Grid, Typography } from '@mui/material';
import Link from 'next/link';
import cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useRef,
  RefObject,
} from 'react';
import IconButton from '@mui/material/IconButton';
import { useNavigationData } from '@hooks/header/useNavigationData';
import { ExpressToggle } from '@components/ExpressToggle/ExpressToggle';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI';
import {
  arrowRight as ArrowRightIcon,
  close as CloseIcon,
  arrowDown as ArrowDownIcon,
  arrowUp as ArrowUpIcon,
} from '@assets/icons/system';
import { ExpressIcon } from '@components/UI/ExpressIcon/ExpressIcon';
import { trackShopBy, trackShopByExpress } from '../../utils';
import styles from './HomePageShopBy.module.scss';

interface HomePageShopByProps {
  isExpanded: boolean;
  isSticky: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
}

const setShopByTitleId = (titleId: string, setTitleId: Dispatch<SetStateAction<string>>) => {
  if (titleId === 'shopBy_title') {
    setTitleId(uniqueId('shopBy_title_'));
  }
};

const moreItems = (
  shopByRef: RefObject<HTMLUListElement | null>,
  shopByContainer: ResizeObserverEntry,
  setShowMoreItems: Dispatch<SetStateAction<boolean>>,
  hasHiddenItems: boolean,
  isShowMoreItemsExpanded: boolean,
) => {
  if (
    shopByRef?.current &&
    shopByContainer.contentRect.width < shopByRef?.current?.getBoundingClientRect().width
  ) {
    setShowMoreItems(true);
    shopByRef?.current?.querySelectorAll('li').forEach((li) => {
      if (
        li.getBoundingClientRect().width + li.getBoundingClientRect().x >
          shopByContainer.contentRect.width ||
        hasHiddenItems
      ) {
        hasHiddenItems = true;
        li.classList.add(styles.hide);
      }
    });
  } else if (
    shopByRef.current &&
    shopByContainer.contentRect.width >= shopByRef.current.getBoundingClientRect().width &&
    !isShowMoreItemsExpanded
  ) {
    setShowMoreItems(false);
  }
};

export const HomePageShopBy = ({ isExpanded, isSticky, setIsExpanded }: HomePageShopByProps) => {
  const { secondaryNavigation } = useNavigationData();
  const { eligible, enabled: expressEnabled } = useExpressContext();
  const { t } = useLocalization();
  const [titleId, setTitleId] = useState('shopBy_title');
  const shopByContainerRef = useRef<HTMLDivElement | null>(null);
  const shopByRef = useRef<HTMLUListElement | null>(null);
  const [showMoreItems, setShowMoreItems] = useState(false);
  const [isShowMoreItemsExpanded, setIsShowMoreItemsExpanded] = useState(false);

  useEffect(() => {
    setShopByTitleId(titleId, setTitleId);
  }, [titleId]);

  const handleExpandShopBy = () => {
    setIsExpanded(true);
  };

  const handleCollapseShopBy = useCallback(() => {
    setIsExpanded(false);
  }, [setIsExpanded]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [expressEnabled]);

  const handleExpressToggle = () => {
    trackShopByExpress(!expressEnabled);
  };

  const handleShopByClick = (itemName?: string | null) => {
    trackShopBy(itemName);
  };

  const resetHideitems = () => {
    shopByRef?.current?.querySelectorAll('li').forEach((li) => {
      li.classList.remove(styles.hide);
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const [shopByContainer] = entries;
      const hasHiddenItems = false;

      resetHideitems();
      moreItems(
        shopByRef,
        shopByContainer,
        setShowMoreItems,
        hasHiddenItems,
        isShowMoreItemsExpanded,
      );
    });

    if (shopByContainerRef.current) {
      resizeObserver.observe(shopByContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [shopByContainerRef.current, secondaryNavigation, isShowMoreItemsExpanded]);

  const renderArrowIcon = () => {
    return isShowMoreItemsExpanded ? (
      <ArrowUpIcon width={10} height={10} />
    ) : (
      <ArrowDownIcon width={10} height={10} />
    );
  };

  return isSticky && !isExpanded ? (
    <Grid
      className={cx(styles.container, styles.mobile_container)}
      data-testid="landing-shop-by"
      container
    >
      <Button
        className={cx(styles.shop_by_button, { [styles.express_enabled]: expressEnabled })}
        variant="outlined"
        onClick={handleExpandShopBy}
      >
        <span className={styles.shop_by_button_text}>{t('filters.shopBy')}</span>
        {expressEnabled && <ExpressIcon className={styles.shop_by_button_express} />}
        <ArrowRightIcon className={styles.shop_by_button_arrow_icon} width={12} height={12} />
      </Button>
    </Grid>
  ) : (
    <div className={styles.wrapper}>
      <Grid
        className={styles.container}
        data-testid="landing-shop-by"
        container
        ref={shopByContainerRef}
      >
        <ul
          className={cx(styles.items, { [styles.show_more_items]: isShowMoreItemsExpanded })}
          ref={shopByRef}
        >
          <li className={styles.shop_by_title}>
            <Typography variant="body" component="span" className={styles.title} id={titleId}>
              {t('filters.shopBy')}
            </Typography>
          </li>
          {eligible && (
            <li className={styles.item}>
              <ExpressToggle className={styles.express} shopBy onToggle={handleExpressToggle} />
            </li>
          )}
          {secondaryNavigation.map((item, index) => {
            const categoryId = `shopBy_category_${index}`;
            const Icon = item?.icon;
            return (
              <li className={styles.item} key={item?.name}>
                <Link
                  prefetch={false}
                  href={item?.link || ''}
                  onClick={() => handleShopByClick(item?.name)}
                  key={item?.name}
                  className={cx([
                    styles.secondary_navigation_link,
                    { [styles.with_icon]: !!item?.icon },
                  ])}
                  aria-labelledby={`${titleId} ${categoryId}`}
                  id={categoryId}
                >
                  {Icon && (
                    <>
                      <div className={styles.icon}>
                        <Icon />
                      </div>
                    </>
                  )}
                  <Typography variant="body">{item?.name}</Typography>
                </Link>
              </li>
            );
          })}
        </ul>
      </Grid>
      {showMoreItems && (
        <IconButton
          className={cx(styles.secondary_navigation_link, styles.show_more_items_button)}
          onClick={() => {
            if (!isShowMoreItemsExpanded) {
              resetHideitems();
            }
            setIsShowMoreItemsExpanded(!isShowMoreItemsExpanded);
          }}
          aria-label="expand"
        >
          {renderArrowIcon()}
        </IconButton>
      )}
      {isSticky && isExpanded && (
        <IconButton
          className={cx(styles.secondary_navigation_link, styles.close_button)}
          onClick={handleCollapseShopBy}
          aria-label="close"
        >
          <CloseIcon width={10} height={10} />
        </IconButton>
      )}
    </div>
  );
};
