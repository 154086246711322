import { gql } from '@apollo/client';
import { FEED_MODULE_FRAGMENT } from '@graphql/fragments/home/feedModuleFragment';

export const GET_HOMEPAGE_FEED = gql`
  query HomeFeed($preview: Boolean, $sectionsStartingFrom: Int = 0, $sectionsToLoad: Int = -1) {
    homepageFeed(
      preview: $preview
      sectionsStartingFrom: $sectionsStartingFrom
      sectionsToLoad: $sectionsToLoad
    ) {
      ...feedModuleFragment
      __typename
    }
  }
  ${FEED_MODULE_FRAGMENT}
`;
